import { template as template_ad153d5778f6444480fd70a8a7923668 } from "@ember/template-compiler";
const WelcomeHeader = template_ad153d5778f6444480fd70a8a7923668(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
